.portfolio__categories {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.btn.cat__btn {
    text-transform: capitalize;
    transition: var(--transition);
}

.btn.cat__btn:hover {
    transform: translateY(0);
}

.card.portfolio__project {
    animation: animateProject 800ms ease-in forwards;
    /* background-color: var(--color-light); */
}

/* Style for the container of the logos to align with the title */
.portfolio__project-logos {
    display: flex;
    align-items: center; /* Aligns items vertically */
    flex-wrap: wrap;
    margin: 0.5rem 0; /* Adds some space above and below the logo container */
}

/* Style for the individual logo when using react-icons */
.portfolio__language-logo {
    font-size: 2.3rem; /* Increase font-size to scale the icon size */
    margin-right: 0.7rem; /* Adds some space to the right of each logo */
    margin-bottom: 0.25rem;
    vertical-align: middle; /* Aligns logos with the text if it's inline */
}
.portfolio__language-logo img {
    /* width: 2.7rem;  */
    /* height: 2.2rem; */
    height: 2.5rem;
    width: 2.3rem;
    margin-left:0.1rem;
}


@keyframes animateProject {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.portfolio__projects {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    align-items: start;
}



.portfolio__project-image {
    width: 100%;
    height: fit-content;
    min-height: 7rem;
    max-height: 15rem;
    background: var(--color-light);
    border-radius: var(--border-radius-2);
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.portfolio__project p {
    margin: 0.5rem 0.2rem;
}

.portfolio__project-cta {
    display: flex;
    gap: 1rem;
}

.portfolio__project:hover .btn.sm {
    border-color: var(--color-white);
}

.portfolio__project:hover .btn.sm.primary {
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .portfolio__projects {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}


/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .portfolio__categories {
        gap: 0.6rem;
    }

    .btn.cat__btn {
        padding: 0.7rem 0.8rem;
    }

    .portfolio__projects {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}